import React from "react";

const WebFooter : React.FC = () => {
    const isMobile : boolean = (/Mobi/i.test(window.navigator.userAgent));
    return (
        <div className={isMobile ? 'mobileFooter' : 'footer'}>
            <div className='flex-col ml-4 py-8'>
                <div className='font-bold text-[12px]'>
                    (주)에스에드 | 대표이사 황영희
                </div>
                <div className='flex-col text-[10px] text-black/50 font-semibold mt-4'>
                    <h2>
                        서울특별시 노원구 노헤로 491, 중원빌딩 4층 4193호 | T : 1877-3006
                    </h2>
                    <h2>
                        사업자등록번호 :  451-81-03048  |  통신판매업 신고번호 : 제 2023-서울 노원-1907 호
                    </h2>
                </div>
            </div>
        </div>
    )
}
export default WebFooter;
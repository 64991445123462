import React from 'react';
import styled from 'styled-components';

const TopBarUl = styled.div`
  ul > li {
    float: left;
    padding: 26px 14px;
  }
  ul > li > a {
    line-height: 1;
    padding: 18px 14px;
  }
  ul > li > a:hover {
    color : #19C4D8;
  }
  .hoverLi {
    float: none;
    line-height: 1;
    font-size: 14px;
  }
  .hoverLi1:hover > a {
    color : #19C4D8;
  }
  .hoverDiv {
    display: none;
    background-color: white;
  }
  .hoverEvent:hover > ul {
    display: block;
  }
`
const TopBar: React.FC = () => {
    return (
        <div className='fixed top-0 w-full'>
            <TopBarUl className='flex w-full items-center h-[80px] border-b bg-white'>
                <div className='text-xl font-bold ml-4'>
                    <h2>에스에드</h2>
                </div>
                <ul className='pl-10'>
                    <li className='relative hoverEvent hoverLi1'>
                        <a href={'/'}>여행자보험</a>
                            <ul className='hoverDiv absolute top-[100%] w-[150px] left-0 border-r border-l border-b rounded-b-xl shadow-lg shadow-black/50 z-20'>
                                <li className='hoverLi'>
                                    <a href={'https://hana.tourinsurance.kr/'} target={'_blank'} rel='noreferrer'>
                                        국내여행 보험
                                    </a>
                                </li>
                                <li className='hoverLi'>
                                    <a href={'https://tour.simg.kr/register'} target={'_blank'} rel='noreferrer'>
                                        해외여행 보험
                                    </a>
                                </li>
                                <li className='hoverLi'>
                                    <a href={'https://tour.simg.kr/climPage'} target={'_blank'} rel='noreferrer'>사고접수</a>
                                </li>
                            </ul>
                    </li>
                    {/*<li className='hover:text-blue-500'>
                        <a href={'/'}>환율비교</a>
                    </li>*/}
                </ul>
            </TopBarUl>
        </div>
    );
};

export default TopBar;
import React, {useEffect, useState} from 'react';
import TopBar from "./components/web/topBar";
import Footer from "./components/footer";
import TravelIcon from './images/travel.png';
import EarthIcon from './images/earth.png';
import BillIcon from './images/sub-hospital-bill-1.svg';
import QrCode from './images/qrcode.png';
import PhoneIcon from './images/phone.png';
import MobileTopBar from "./components/mobileTopBar";
function App() {
    const [category, setCategory] = useState('step1');
    const isMobile : boolean = (/Mobi/i.test(window.navigator.userAgent));
    useEffect(() => {

    }, [category]);
  return (
    <div className="App w-full">
        {
            isMobile ?
                <>
                    <MobileTopBar />
                        <div className='bg-[#94ddec] w-full h-[300px] flex items-center justify-center'>
                            <div className='absolute flex-col'>
                                <div className='text-[25px] font-bold text-white flex mt-4'>
                                    <h2>쉽고 간편한</h2><h2 className='ml-4 text-yellow-300'>여행자 보험</h2>
                                </div>
                                <div className='mt-8 basis-1/2 border h-[60px] flex items-center justify-center rounded-2xl font-bold text-lg bg-white relative'>
                                    <a className='w-full h-full flex justify-center items-center' href={'https://tour.simg.kr/'} target={'_blank'} rel='noreferrer'>보험료 확인하기</a>
                                    <img className='absolute left-[80%] rotate-12' src={BillIcon} alt={'bil'}/>
                                </div>
                            </div>
                        </div>
                    <div className='flex w-full my-8'>
                        <div className='mx-4 w-full flex-col justify-center items-center'>
                            <div className='flex w-full items-center h-[80px] mx-auto my-0 border-b'>
                                <button className={category === 'step1' ? 'py-[27px] px-[25px] border-b-2 border-b-black text-[#94ddec] font-bold' : 'py-[27px] px-[25px]'} onClick={(e:React.MouseEvent<HTMLElement>) => setCategory('step1')}>
                                    해외여행자보험
                                </button>
                                <button className={category === 'step2' ? 'py-[27px] px-[25px] border-b-2 border-b-black text-[#94ddec] font-bold' : 'py-[27px] px-[25px]'} onClick={(e:React.MouseEvent<HTMLElement>) => setCategory('step2')}>
                                    국내여행자보험
                                </button>
                            </div>
                            {
                                category === 'step1'
                                    ?
                                    <div className='flex items-center border-b py-8 justify-center h-[300px]'>
                                        <div className='flex-col flex  items-center pl-8 text-[12px]'>
                                           <div className='flex items-center mt-2'>
                                               <img src={QrCode} alt={'qr'} width={100}/>
                                               <img src={PhoneIcon} className='rotate-12 mt-2' alt={'phone'} width={160}/>
                                           </div>
                                            <div className='flex font-bold text-lg flex-col items-center mt-4'>
                                                <h2>어디서든 모바일로</h2>
                                                <h2>쉽고 간편하게!</h2>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='flex py-8 border-b  justify-center h-[300px]'>
                                        <div className='flex-col flex  items-center'>
                                            <div className='flex-col flex mt-8'>
                                                <a href={'https://hana.tourinsurance.kr/'} target={'_blank'} rel='noreferrer' className='text-[24px] font-bold border-2 text-center rounded-lg py-2'><span className='text-[#94ddec]'>단체</span> 국내여행자보험</a>
                                                <span className='mt-8 text-[12px]'>단체 인원의 특성에 맞는 다양한 선택 담보, 인원수에 따른 단체할인 혜택 가능하며 5인 이상 학교·법인 등 단체 가입 가능한 여행보험</span>
                                            </div>
                                        </div>
                                       {/* <div className='ml-auto py-6'>
                                            <img src={TravelIcon} className='rotate-12 mt-2' alt={'phone'} width={160}/>
                                        </div>*/}
                                    </div>
                            }
                        </div>
                    </div>
                    <Footer />
                </>
                :
                <>
                    <TopBar />
                    <div className='pt-[80px] pb-[90px] w-full'>
                        <div className='bg-[#94ddec] w-full h-[300px] flex'>
                            <div className='absolute top-[180px] left-[300px] flex-col'>
                                <div className='text-[30px] font-bold text-white flex w-[400px]'>
                                    <h2>쉽고 간편한</h2><h2 className='ml-4 text-yellow-300'>여행자 보험</h2>
                                </div>
                                <div className='mt-8 basis-1/2 border h-[60px] flex items-center justify-center rounded-2xl font-bold text-lg bg-white relative'>
                                    <a className='w-full h-full flex justify-center items-center' href={'https://tour.simg.kr/'} target={'_blank'} rel='noreferrer'>보험료 확인하기</a>
                                    <img className='absolute left-[90%] rotate-12' src={BillIcon} alt={'bil'}/>
                                    <img src={EarthIcon} alt={'earth'} width={160} className='absolute left-[280%] -bottom-8 rotate-[30deg] '/>
                                </div>
                            </div>
                        </div>
                        <div className='flex w-full my-8'>
                            <div className='mx-4 w-full flex-col justify-center items-center'>
                                <div className='flex w-[1060px] items-center h-[80px] mx-auto my-0 border-b'>
                                    <button className={category === 'step1' ? 'py-[27px] px-[25px] border-b-2 border-b-black text-[#94ddec] font-bold' : 'py-[27px] px-[25px]'} onClick={(e:React.MouseEvent<HTMLElement>) => setCategory('step1')}>
                                        해외여행자보험
                                    </button>
                                    <button className={category === 'step2' ? 'py-[27px] px-[25px] border-b-2 border-b-black text-[#94ddec] font-bold' : 'py-[27px] px-[25px]'} onClick={(e:React.MouseEvent<HTMLElement>) => setCategory('step2')}>
                                        국내여행자보험
                                    </button>
                                </div>
                                {
                                    category === 'step1'
                                        ?
                                        <div className='flex w-[1060px] items-center mx-auto my-0 border-b py-8'>
                                            <div className='flex-col flex  items-center pl-8 text-[12px]'>
                                                <img src={QrCode} alt={'qr'} width={100}/>
                                                <div className='flex-col flex items-center mt-2'>
                                                    <span> ※ 본 상품은 모바일로 가입 가능합니다. </span>
                                                    <span> 가입을 원하시면 모바일기기로QR코드를 촬영해주세요. </span>
                                                </div>
                                            </div>
                                            <div className='ml-auto'>
                                                <div className='flex font-bold text-lg flex-col items-center'>
                                                    <h2>어디서든 모바일로</h2>
                                                    <h2>쉽고 간편하게!</h2>
                                                </div>
                                                <img src={PhoneIcon} className='rotate-12 mt-2' alt={'phone'} width={160}/>
                                            </div>
                                        </div>
                                        :
                                        <div className='flex w-[1060px] items-center mx-auto my-0 border-b py-8'>
                                            <div className='flex-col flex  items-center pl-8'>
                                                <div className='flex-col flex mt-2'>
                                                    <a href={'https://hana.tourinsurance.kr/'} target={'_blank'} rel='noreferrer' className='text-[28px] font-bold border-2 text-center rounded-lg py-2'><span className='text-[#94ddec]'>단체</span> 국내여행자보험</a>
                                                    <span className='w-[500px] mt-4'>단체 인원의 특성에 맞는 다양한 선택 담보, 인원수에 따른 단체할인 혜택 가능하며 5인 이상 학교·법인 등 단체 가입 가능한 여행보험</span>
                                                </div>
                                            </div>
                                            <div className='ml-auto py-6'>
                                                <img src={TravelIcon} className='rotate-12 mt-2' alt={'phone'} width={160}/>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    <Footer />
                </>
        }

    </div>
  );
}

export default App;
